@font-face {
    font-family: 'Walsheim';
    font-weight: 700;
    src: local('Proto Grotesk'), url(./assets/fonts/GT-Walsheim-Pro-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Walsheim';
    font-weight: 400;
    src: local('Proto Grotesk'), url(./assets/fonts/GT-Walsheim-Pro-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Pressura';
    font-weight: 400;
    src: local('Proto Grotesk'), url(./assets/fonts/GT-Pressura-Pro-Regular.otf) format('opentype');
}

html {
    font-size: 80%;
}

#root {
    white-space: pre-line;
}

main, .home, .travelInfo{
    background-image: url('assets/images/bkg2.jpg');
    background-position: center center;
    background-size: cover;
    /*background: #111;*/
}

main {
    width: 100%;
    position: relative;
    min-height: max(100vh, 53.125rem);
    font-family: "Walsheim", sans-serif;
}

.home {
    min-height: max(100vh, 53.125rem);
}

.travelInfo {
    width: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vjs-error .vjs-error-display:before {
    display: none;
}

@media screen and (max-width: 768px) {
    main {
        justify-content: flex-start;
        min-height: max(100vh, 40.625rem);
    }
}

@media (max-resolution: 120dpi) {
    html {
        font-size: 70%;
    }
}
